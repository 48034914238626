import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  COMMENT_LOADING_REQUEST,
  LOADING_REQUEST,
  POST_DELETE_REQUEST,
  POST_DETAIL_LOADING_REQUEST,
} from '../../redux/types';
import { Button, Col, Row, Container } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { GrowingSpinner } from '../../components/spinner/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPencilAlt,
  faCommentDots,
  faMouse,
} from '@fortawesome/free-solid-svg-icons';
import CkEditor from '../../components/CkEditor';
import { BalloonEditor } from 'ckeditor5';
import Comments from '../../components/comments/Comments';
import Category from '../../components/post/Category';

const PostDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { postDetail, creatorId, title, loading } = useSelector(
    (state) => state.post
  );
  const { userId, userName } = useSelector((state) => state.auth);
  //test
  console.log('CreatorId, userId in Detail: ', creatorId, userId);
  console.log('포스트디테일: ', postDetail);
  useEffect(() => {
    dispatch({
      type: POST_DETAIL_LOADING_REQUEST,
      payload: id,
    });
    dispatch({
      type: LOADING_REQUEST,
      payload: localStorage.getItem('token'),
    });
  }, []);

  const onDelete = () => {
    dispatch({
      type: POST_DELETE_REQUEST,
      payload: {
        id: id,
        token: localStorage.getItem('token'),
      },
    });
  };

  //test
  // console.log('comments: ', comments);
  const EditButton = () => (
    <>
      <Row className="d-flex justify-content-center pb-3">
        <Col className="col-md-3 mr-md-3">
          <Link to={'/'} className="btn btn-primary btn-block">
            Home
          </Link>
        </Col>
        <Col className="col-md-3 mr-md-3">
          <Link className="btn btn-primary btn-block" to={`/post/${id}/edit`}>
            Edit Post
          </Link>
        </Col>
        <Col className="col-md-3 mr-md-3">
          <Button className="btn-block btn-danger" onClick={onDelete}>
            Delete
          </Button>
        </Col>
      </Row>
    </>
  );

  const HomeButton = () => (
    <>
      <Row className="d-flex justify-content-center pb-3">
        <Col className="col-sm-12 col-md-3">
          <Link
            to={'/'}
            className="btn btn-primary btn-block"
            style={{ width: '100%' }}
          >
            Home
          </Link>
        </Col>
      </Row>
    </>
  );
  const Body = () => (
    <>
      {userId === creatorId ? <EditButton /> : <HomeButton />}
      <Row
        className="border-bottom border-top border-primary p-3 mb-3 justify-content-between"
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          flexWrap: 'nowrap',
          alignItems: 'center',
        }}
      >
        {postDetail && postDetail.creator && (
          <>
            <div
              className="font-weight-bold text-big"
              style={{ display: 'flex', width: 'auto', alignItems: 'center' }}
            >
              <span className="mr-3">
                <Button color="info">{postDetail.category.name}</Button>
              </span>
              <>{postDetail.title}</>
            </div>
            <div
              style={{ display: 'flex', width: 'auto', alignItems: 'center' }}
            >
              {postDetail.creator.name}
            </div>
          </>
        )}
      </Row>
      {postDetail && postDetail.contents ? (
        <>
          <div
            contents={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
            }}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
            &nbsp;
            <span>{postDetail.date}</span>
            &nbsp;&nbsp;
            <FontAwesomeIcon icon={faCommentDots} />
            &nbsp;
            <span>{postDetail.comments.length}</span>
            &nbsp;&nbsp;
            <FontAwesomeIcon icon={faMouse} />
            <span>{postDetail.views}</span>
          </div>
          <Row>
            <CkEditor
              editor={BalloonEditor}
              data={postDetail.contents}
              disabled={true}
            />
          </Row>
          <Row>
            <Comments id={id} userId={userId} userName={userName} />
          </Row>
        </>
      ) : null}
    </>
  );
  // ㅅㄷㄴㅅ
  console.log(
    'LOADING, userId, creatorID, postDetail: ',
    loading,
    userId,
    creatorId,
    postDetail
  );
  return (
    <>
      <div>
        <Helmet title={`Post | ${title}`} />
        {loading ? <GrowingSpinner /> : <Body />}
      </div>
    </>
  );
};

export default PostDetail;
