import React from 'react';
import { Provider } from 'react-redux';
import store, { history } from './store.js';
// import { ConnectedRouter } from 'connected-react-router';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import MyRouter from './routes/Router.js';

// css
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/custom.scss';

function App() {
  return (
    <>
      <Provider store={store}>
        <Router history={history}>
          <MyRouter />
        </Router>
      </Provider>
    </>
  );
}

export default App;
