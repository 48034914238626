import { Link } from 'react-router-dom';
import { Badge, Button } from 'reactstrap';

const Category = ({ categories }) => {
  //test
  console.log('CATEs:  ', categories);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '1rem',
        flexWrap: 'wrap',
      }}
    >
      {Array.isArray(categories)
        ? categories.map(({ _id, name, posts }) => (
            <div key={_id} className="mx-1 mt-1 my_category">
              <Link
                to={`/post/category/${name}`}
                className="text-dark text-decoration-none"
              >
                <span className="ml-1">
                  <Button
                    color="info"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '1rem',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <p
                      style={{
                        width: 'auto',
                        height: 'auto',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      {name}
                    </p>
                    <Badge color="light" style={{ color: 'black' }}>
                      {posts.length}
                    </Badge>
                  </Button>
                </span>
              </Link>
            </div>
          ))
        : ''}
    </div>
  );
};
export default Category;
