import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CATEGORY_RESULT_LOADING_REQUEST } from '../../redux/types';
import { useParams } from 'react-router-dom';
import { Row } from 'reactstrap';
import PostCardOne from '../../components/post/PostCardOne';

const CategoryResult = () => {
  const { categoryName } = useParams();
  // const name = '';

  const { posts } = useSelector((state) => state.post);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: CATEGORY_RESULT_LOADING_REQUEST, payload: categoryName });
    console.log('아니 여기는 와야한다니까');
  }, [dispatch, categoryName]);
  //test
  console.log('CATE name, RES: ', categoryName, posts);

  return (
    <>
      <h1>Category: "{categoryName}"</h1>
      <Row>
        {posts.length !== 0 ? (
          <PostCardOne posts={posts} />
        ) : (
          <p>관련 포스트가 없습니다</p>
        )}
      </Row>
    </>
  );
};

export default CategoryResult;
