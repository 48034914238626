import { Row, Spinner } from 'reactstrap';

export const GrowingSpinner = () => {
  return (
    <>
      <Row className="d-flex jusitfy-content-center m-5">
        <>
          <Spinner
            style={{ width: '2rem', height: '2rem' }}
            color="primary"
            type="grow"
          />
          <Spinner
            style={{ width: '2rem', height: '2rem' }}
            color="secondary"
            type="grow"
          />
          <Spinner
            style={{ width: '2rem', height: '2rem' }}
            color="success"
            type="grow"
          />
          <Spinner
            style={{ width: '2rem', height: '2rem' }}
            color="danger"
            type="grow"
          />
          <Spinner
            style={{ width: '2rem', height: '2rem' }}
            color="warning"
            type="grow"
          />
          <Spinner
            style={{ width: '2rem', height: '2rem' }}
            color="info"
            type="grow"
          />
          <Spinner
            style={{ width: '2rem', height: '2rem' }}
            color="light"
            type="grow"
          />
          <Spinner
            style={{ width: '2rem', height: '2rem' }}
            color="dark"
            type="grow"
          />
        </>
      </Row>
    </>
  );
};
