import { all, fork } from 'redux-saga/effects';
import authSaga from './authSaga';
import axios from 'axios';
import postSaga from './postSaga';
import commentSaga from './commentSaga';
// import dotenv from 'dotenv';
// dotenv.config();

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

export default function* rootSaga() {
  yield all([fork(authSaga), fork(postSaga), fork(commentSaga)]);
}
