import store from '../../store.js';
import { LOADING_REQUEST } from '../../redux/types.js';

const loadUser = () => {
  // test
  console.log('로드유저', localStorage.getItem('token'));
  try {
    store.dispatch({
      type: LOADING_REQUEST,
      payload: localStorage.getItem('token'),
    });
  } catch (Error) {
    console.error(Error);
  }
};
export default loadUser;
