import {
  legacy_createStore as createStore, // configureStore하고 추가로 뭐 하기 귀찮아서 일단 이거 씀.
  compose,
  applyMiddleware,
  combineReducers,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
// import { routerMiddleware } from 'connected-react-router';
import { createReduxHistoryContext } from 'redux-first-history';
// import createRootReducer from './redux/reducers';
import rootSaga from './redux/sagas';

import otherReducers from './redux/reducers';

// export const history = createBrowserHistory();

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({ history: createBrowserHistory() });

const sagaMiddleWare = createSagaMiddleware();

const initialState = {};

const middleWares = [sagaMiddleWare, routerMiddleware];
// 개발자도구에서 redux탭
const devtools = window.__REDUX_DEVTOOLS_EXTENTION_COMPOSE__;

// 배포할 때 devtools제외
const composeEnhancer =
  process.env.MODE_ENV === 'production' ? compose : devtools || compose;

const store = createStore(
  //   createRootReducer(history),
  combineReducers({
    router: routerReducer,
    ...otherReducers,
  }),
  initialState,
  composeEnhancer(applyMiddleware(...middleWares))
);

// 사가 미들웨어 실행.
sagaMiddleWare.run(rootSaga);

export const history = createReduxHistory(store);
export default store;
