// import { combineReducers } from 'redux';
// import { connectRouter } from 'connected-react-router';

import authSaga from '../sagas/authSaga';
import authReducer from './authReducer';
import postReducer from './postReducer';
import commentReducer from './commentReducer';
// const createRootReducer = (history) =>
//   combineReducers({
//     router: connectRouter(history),
//   });

// export default createRootReducer;

const otherReducers = {
  auth: authReducer,
  post: postReducer,
  comment: commentReducer,
};

export default otherReducers;
