import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SEARCH_REQUEST } from '../../redux/types';
import { Form, Input } from 'reactstrap';

const SearchInput = () => {
  const dispatch = useDispatch();
  const [formValue, setFormValue] = useState({ searchBy: '' });
  const searchInputRef = useRef(null);
  const onChange = (e) => {
    setFormValue({
      ...formValue,
      [e.target.name]: e.target.value,
    });
  };
  const onSubmit = async (e) => {
    //test
    console.log('서브밋');
    e.preventDefault();
    dispatch({
      type: SEARCH_REQUEST,
      payload: formValue.searchBy,
    });

    // 검색 텍스트 초기화
    searchInputRef.current.value = '';
  };
  return (
    <>
      <Form onSubmit={onSubmit} className="col mt-2">
        <Input name="searchBy" onChange={onChange} innerRef={searchInputRef} />
      </Form>
    </>
  );
};
export default SearchInput;
