import { useSelector } from 'react-redux';
import { Navigate, Route } from 'react-router-dom';
import PostEdit from '../publicRoute/PostEdit';

const EditProtectedRoute = () => {
  const { userId } = useSelector((state) => state.auth);
  const { creatorId } = useSelector((state) => state.post);
  console.log(
    '아니 여까진 와야 할거아님;; userId, creatorId: ',
    userId,
    creatorId,
    userId === creatorId
  );
  console.log(userId === '' ? '와' : '아뇨');
  return (
    <>
      {userId && userId === creatorId ? (
        <PostEdit />
      ) : (
        <>
          <Navigate to={{ pathname: '/' }} />
        </>
      )}
    </>
  );
};

export default EditProtectedRoute;
