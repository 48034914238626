import {
  CATEGORY_RESULT_LOADING_FAILURE,
  CATEGORY_RESULT_LOADING_REQUEST,
  CATEGORY_RESULT_LOADING_SUCCESS,
  POST_DETAIL_LOADING_FAILURE,
  POST_DETAIL_LOADING_REQUEST,
  POST_DETAIL_LOADING_SUCCESS,
  POST_EDIT_LOADING_FAILURE,
  POST_EDIT_LOADING_REQUEST,
  POST_EDIT_LOADING_SUCCESS,
  POST_EDIT_UPLOADING_FAILURE,
  POST_EDIT_UPLOADING_REQUEST,
  POST_EDIT_UPLOADING_SUCCESS,
  POST_LOADING_FAILURE,
  POST_LOADING_REQUEST,
  POST_LOADING_SUCCESS,
  POST_WRITE_FAILURE,
  POST_WRITE_REQUEST,
  POST_WRITE_SUCCESS,
  SEARCH_FAILURE,
  SEARCH_REQUEST,
  SEARCH_SUCCESS,
} from '../types';

const initialState = {
  isAuthenticated: null,
  posts: [],
  postDetail: '',
  postCount: '',
  loading: false,
  error: '',
  creatorId: '',
  categoryFindResult: '',
  title: '',
  searchBy: '',
  searchResult: [],
};

const postReducer = (state = initialState, action) => {
  // test
  console.log('PR! ', action);
  switch (action.type) {
    case POST_LOADING_REQUEST:
      //tes
      console.log('포스트 로딩 요청이요');
      return { ...state, loading: true, categoryFindResult: [] };

    case POST_LOADING_SUCCESS:
      //test
      console.log('비어있어야 하는데?: ', state.categoryFindResult);
      return {
        ...state,
        loading: false,
        posts: [...state.posts, ...action.payload.postFindResult],
        categoryFindResult: [
          ...state.categoryFindResult,
          ...action.payload.categoryFindResult,
        ],
        postCount: action.payload.postCount,
      };
    case POST_LOADING_FAILURE:
      return { ...state, loading: false, posts: [], categoryFindResult: [] };
    case POST_WRITE_REQUEST:
      return { ...state, loading: true, posts: [] };
    case POST_WRITE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case POST_WRITE_FAILURE:
      return { ...state, loading: false };
    case POST_DETAIL_LOADING_REQUEST:
      return { ...state, loading: true, posts: [] };
    case POST_DETAIL_LOADING_SUCCESS:
      return {
        ...state,
        loading: false,
        postDetail: action.payload,
        creatorId: action.payload.creator._id,
        title: action.payload.title,
      };
    case POST_DETAIL_LOADING_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case POST_EDIT_LOADING_REQUEST:
      return { ...state, loading: true };
    case POST_EDIT_LOADING_SUCCESS:
      return {
        ...state,
        loading: false,
        postDetail: action.payload,
        isAuthenticated: true,
      };
    case POST_EDIT_LOADING_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case POST_EDIT_UPLOADING_REQUEST:
      return { ...state, loading: true };
    case POST_EDIT_UPLOADING_SUCCESS:
      return {
        ...state,
        loading: false,
        postDetail: action.payload,
        isAuthenticated: true,
      };
    case POST_EDIT_UPLOADING_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case CATEGORY_RESULT_LOADING_REQUEST:
      //test
      console.log('카테 여기는 ㅗ아야지');
      return { ...state, loading: true, posts: [] };
    case CATEGORY_RESULT_LOADING_SUCCESS:
      return {
        ...state,
        loading: false,
        posts: action.payload.posts,
        isAuthenticated: true,
      };
    case CATEGORY_RESULT_LOADING_FAILURE:
      return { ...state, error: action.payload, loading: false };
    case SEARCH_REQUEST:
      return {
        ...state,
        loading: true,
        searchBy: action.payload,
        searchResult: '', // 얘를 초기화해야 하는거 아님? posts가 아니라?
      };
    case SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        searchResult: action.payload,
      };
    case SEARCH_FAILURE:
      return {
        ...state,
        searchResult: '결과가 없다고 해야하지 않나?',
        loading: false,
      };
    default:
      return state;
  }
};
export default postReducer;
