import { fork, all, put, takeEvery, call } from 'redux-saga/effects';
import { push } from 'redux-first-history';
import {
  CATEGORY_RESULT_LOADING_FAILURE,
  CATEGORY_RESULT_LOADING_REQUEST,
  CATEGORY_RESULT_LOADING_SUCCESS,
  POST_DELETE_FAILURE,
  POST_DELETE_REQUEST,
  POST_DELETE_SUCCESS,
  POST_DETAIL_LOADING_FAILURE,
  POST_DETAIL_LOADING_REQUEST,
  POST_DETAIL_LOADING_SUCCESS,
  POST_EDIT_LOADING_FAILURE,
  POST_EDIT_LOADING_REQUEST,
  POST_EDIT_LOADING_SUCCESS,
  POST_EDIT_UPLOADING_FAILURE,
  POST_EDIT_UPLOADING_REQUEST,
  POST_EDIT_UPLOADING_SUCCESS,
  POST_LOADING_FAILURE,
  POST_LOADING_REQUEST,
  POST_LOADING_SUCCESS,
  POST_UPLOADING_FAILURE,
  POST_UPLOADING_REQUEST,
  POST_UPLOADING_SUCCESS,
  POST_WRITE_FAILURE,
  POST_WRITE_REQUEST,
  POST_WRITE_SUCCESS,
  SEARCH_FAILURE,
  SEARCH_REQUEST,
  SEARCH_SUCCESS,
} from '../types';
import axios from 'axios';

// ALL POSTS LOAD
const fetchPostLoad = (getPostCount) => {
  //test
  console.log('getPostcount in saga: ', getPostCount);
  return axios.get(`/api/post/skip/${getPostCount}`);
};

function* postLoad(action) {
  try {
    const result = yield call(fetchPostLoad, action.payload);
    // test
    console.log('post get result: ', result);
    yield put({ type: POST_LOADING_SUCCESS, payload: result.data });
  } catch (e) {
    console.error(e);
    yield put({ type: POST_LOADING_FAILURE });
  }
  yield put(push('/'));
}

function* watchPostLoad() {
  yield takeEvery(POST_LOADING_REQUEST, postLoad);
}

// POST UPLOAD
const fetchPostUpload = (payload) => {
  const config = { headers: {} };
  const token = payload.token;
  if (token) {
    // 이게 필요한가? => 포스팅 페이지 접속 못하게 하면 안되나 => 포스팅 페이지 못가도 업로드가 가능할 수 있나? = api주소 알면 가능. 따라서 token있어야함.
    config.headers['Authorization'] = token;
  }

  return axios.post('/api/post', payload, config);
};

function* postUpload(action) {
  try {
    //test
    console.log('ACTION: ', action);

    const result = yield call(fetchPostUpload, action.payload);
    // test
    console.log('post get result, action: ', result);
    yield put({ type: POST_WRITE_SUCCESS, payload: result.data });
    yield put(push(`/post/${result.data._id}`)); // 해당 post id(디테일 페이지)로 이동
  } catch (e) {
    console.error(e);
    yield put({ type: POST_WRITE_FAILURE, payload: e });
  }
  // yield put(push('/'));
}

function* watchPostUpload() {
  yield takeEvery(POST_WRITE_REQUEST, postUpload);
}

// DETAIL
const fetchPostDetail = (id) => {
  return axios.get(`/api/post/${id}`);
};

function* postDetail(action) {
  try {
    const result = yield call(fetchPostDetail, action.payload);
    // test
    console.log('post detail get result: ', result);
    yield put({ type: POST_DETAIL_LOADING_SUCCESS, payload: result.data });
    yield put(push(`/post/${result.data._id}`));
  } catch (e) {
    console.error(e);
    yield put({ type: POST_DETAIL_LOADING_FAILURE, payload: e });
    yield put(push('/'));
  }
}

function* watchPostDetail() {
  yield takeEvery(POST_DETAIL_LOADING_REQUEST, postDetail);
}

//DELETE
const fetchPostDelete = (payload) => {
  const config = { headers: {} };
  if (payload.token) {
    config.headers['Authorization'] = payload.token;
  }
  return axios.delete(`/api/post/${payload.id}`, config);
};

function* postDelete(action) {
  try {
    const result = yield call(fetchPostDelete, action.payload);
    // test
    console.log('post detail get result: ', result);
    yield put({ type: POST_DELETE_SUCCESS, payload: result.data });
    yield put(push(`/`));
  } catch (e) {
    console.error(e);
    yield put({ type: POST_DELETE_FAILURE, payload: e });
  }
}

function* watchPostDelete() {
  yield takeEvery(POST_DELETE_REQUEST, postDelete);
}

//EDIT LOAD
const fetchPostEditLoad = (payload) => {
  const config = { headers: {} };
  if (payload.token) {
    config.headers['Authorization'] = payload.token;
  }
  return axios.get(`/api/post/${payload.id}/edit`, config);
};

function* postEditLoad(action) {
  try {
    const result = yield call(fetchPostEditLoad, action.payload);
    // test
    console.log('post detail get result: ', result);
    yield put({ type: POST_EDIT_LOADING_SUCCESS, payload: result.data });
  } catch (e) {
    console.error(e);
    yield put({ type: POST_EDIT_LOADING_FAILURE, payload: e });
    // yield put(push('/'));
  }
}

function* watchPostEditLoad() {
  yield takeEvery(POST_EDIT_LOADING_REQUEST, postEditLoad);
}

//EDIT UPLOAD
const fetchPostEditUpload = (payload) => {
  const config = { headers: {} };
  if (payload.token) {
    config.headers['Authorization'] = payload.token;
  }
  const body = {
    title: payload.title,
    contents: payload.contents,
    fileUrl: payload.fileUrl,
    id: payload.id,
  };
  return axios.post(`/api/post/${payload.id}/edit`, body, config);
};

function* postEditUpload(action) {
  try {
    const result = yield call(fetchPostEditUpload, action.payload);
    // test
    console.log('post edit 리다이렉션했는데 반환이 있나?: ', result);
    yield put({ type: POST_EDIT_UPLOADING_SUCCESS, payload: result.data });
    yield put(push(`/post/${result.data._id}`));
  } catch (e) {
    console.error(e);
    yield put({ type: POST_EDIT_UPLOADING_FAILURE, payload: e });
  }
}

function* watchPostEditUpload() {
  yield takeEvery(POST_EDIT_UPLOADING_REQUEST, postEditUpload);
}

/// CATEGORY
//CATEGORY RESULT LOAD
const fetchCategoryResultLoad = (categoryName) => {
  return axios.get(`/api/post/category/${encodeURIComponent(categoryName)}`);
};

function* categoryResultLoad(action) {
  try {
    const result = yield call(fetchCategoryResultLoad, action.payload);
    // test
    console.log('카테고리 찾는 결과: ', result);
    yield put({ type: CATEGORY_RESULT_LOADING_SUCCESS, payload: result.data });
  } catch (e) {
    console.error(e);
    yield put({ type: CATEGORY_RESULT_LOADING_FAILURE, payload: e });
  }
}

function* watchCategoryResultLoad() {
  yield takeEvery(CATEGORY_RESULT_LOADING_REQUEST, categoryResultLoad);
}

//SEARCH
const fetchSearchResult = (searchTerm) => {
  //test
  console.log('서치텀: ', searchTerm);
  return axios.get(`/api/search/${searchTerm}`);
};

function* searchResult(action) {
  try {
    const result = yield call(fetchSearchResult, action.payload);
    // test
    console.log('검색 결과: ', result);
    yield put({ type: SEARCH_SUCCESS, payload: result.data });
    yield put(push(`/search/${encodeURIComponent(action.payload)}`));
  } catch (e) {
    console.error(e);
    yield put({ type: SEARCH_FAILURE, payload: e });
    yield put(push('/'));
  }
}

function* watchSearchResult() {
  yield takeEvery(SEARCH_REQUEST, searchResult);
}

function* postSaga() {
  yield all([
    fork(watchPostLoad),
    fork(watchPostUpload),
    fork(watchPostDetail),
    fork(watchPostDelete),
    fork(watchPostEditLoad),
    fork(watchPostEditUpload),
    fork(watchCategoryResultLoad),
    fork(watchSearchResult),
  ]);
}
export default postSaga;
