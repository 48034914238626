import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SEARCH_REQUEST } from '../../redux/types';
import { Row } from 'reactstrap';
import PostCardOne from '../../components/post/PostCardOne';

const Search = () => {
  const dispatch = useDispatch();
  const { searchTerm } = useParams();
  const { searchResult } = useSelector((state) => state.post);

  useEffect(() => {
    // 이 페이지에 넘어오면서 검색되는 방법인 듯.
    // test

    if (searchTerm) {
      dispatch({ type: SEARCH_REQUEST, payload: searchTerm });
    }
  }, [searchTerm, dispatch]);

  console.log('검색 되나용?', searchResult);
  return (
    <>
      <div>
        <h1>검색결과: "{searchTerm}"</h1>
        <Row>
          <PostCardOne posts={searchResult} />
        </Row>
      </div>
    </>
  );
};

export default Search;
