import { token } from 'morgan';
import {
  CLEAR_ERROR_FAILURE,
  CLEAR_ERROR_REQUEST,
  CLEAR_ERROR_SUCCESS,
  LOADING_FAILURE,
  LOADING_REQUEST,
  LOADING_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  PROFILE_UPDATE_FAILURE,
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_SUCCESS,
  REGISTER_FAILURE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
} from '../types';
import { errorMonitor } from 'stream';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  isLoading: false, // spinner위해
  user: '',
  userId: '',
  userName: '',
  userRole: '',
  errorMsg: '',
  successMsg: '',
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_REQUEST:
    case LOGIN_REQUEST:
    case LOGOUT_REQUEST:
      return {
        ...state,
        errorMsg: '',
        isLoading: true,
      };
    case REGISTER_SUCCESS:
      localStorage.setItem('token', action.payload.token);
      return {
        ...state,
        ...action.payload, // token
        isAuthenticated: true,
        isLoading: false,
        userId: action.payload.user.id,
        userRole: action.payload.user.role, // role을 api에서 안주는데? 이거 지켜보기
        errorMsg: '',
      };
    case LOGIN_SUCCESS:
      localStorage.setItem('token', action.payload.token);
      return {
        ...state,
        ...action.payload, // token
        isAuthenticated: true,
        isLoading: false,
        userId: action.payload.user.id,
        userRole: action.payload.user.role,
        errorMsg: '',
      };
    case LOGOUT_SUCCESS:
      localStorage.removeItem('token');
      return {
        token: null,
        user: null,
        userId: null,
        isAuthenticated: false,
        isLoading: false,
        userRole: null,
        errorMsg: '',
      };

    case LOGIN_FAILURE:
      localStorage.removeItem('token');
      return {
        ...state,
        ...action.payload,
        token: null,
        user: null,
        userId: null,
        isAuthenticated: false,
        isLoading: false,
        userRole: null,
        errorMsg: action.payload.data.msg,
      };
    case REGISTER_FAILURE:
    case LOGOUT_FAILURE: // 로그인 실패랑은 다르지 않나? 로그인 상태에서 로그아웃해야 하는거 아님?
      return {
        ...state,
        ...action.payload,
        errorMsg: action.payload.data.msg,
      };

    case LOADING_REQUEST:
      //test
      console.log('로딩 요청 state: ', state);
      return {
        ...state,
        isLoading: true,
      };
    case LOADING_SUCCESS:
      //test
      console.log('로딩 성공 state: ', state, action);
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload,
        userId: action.payload._id,
        userName: action.payload.name,
        userRole: action.payload.role,
        // 추가
        errorMsg: '',
      };
    case LOADING_FAILURE:
      // test
      console.log('로딩 실패 state: ', state);
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
        userRole: '',
      };
    case PROFILE_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        errorMsg: '',
        successMsg: '',
      };
    case PROFILE_UPDATE_SUCCESS:
      //test
      console.log('프로파일 업뎃 성공 state: ', state, action);
      return {
        ...state,
        isLoading: false,
        successMsg: action.payload.successMsg,
        errorMsg: '',
      };
    case PROFILE_UPDATE_FAILURE:
      // test
      console.log('프로파일 업뎃 실패 payload: ', action.payload);
      return {
        ...state,
        isLoading: false,
        errorMsg: action.payload.matchMsg
          ? action.payload.matchMsg
          : action.payload.failureMsg ?? '',
      };

    case CLEAR_ERROR_REQUEST:
      return {
        ...state,
        errorMsg: '',
      };
    case CLEAR_ERROR_SUCCESS:
      return {
        ...state,
        errorMsg: '',
      };
    case CLEAR_ERROR_FAILURE:
      return {
        ...state,
        errorMsg: 'Clear Error Fail',
      };

    default:
      return state;
  }
};

export default authReducer;
