import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMouse } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import {
  CardBody,
  CardImg,
  CardTitle,
  Card,
  Row,
  Button,
  Badge,
} from 'reactstrap';
import tmpImg from './thumb.jpg';

const PostCardOne = ({ posts }) => {
  console.log('POSTS LEN', posts.length);
  return (
    <>
      {Array.isArray(posts)
        ? posts.map(({ _id, title, fileUrl, comments, views }) => {
            console.log('fileURL, id,comments: ', fileUrl, _id, comments);
            return (
              <div key={_id} className="col-md-4">
                <Link
                  className="text-dark text-decoration-none"
                  to={`/post/${_id}`}
                >
                  <Card className="mb-3">
                    <CardImg top alt="커버이미지" src={fileUrl ?? tmpImg} />
                    <CardBody>
                      <CardTitle className="text-truncate d-flex justify-content-between">
                        <span className="text-truncate">{title}</span>
                        <span>
                          <FontAwesomeIcon icon={faMouse} />
                          &nbsp; &nbsp;
                          <span>{views}</span>
                        </span>
                      </CardTitle>
                      <Row>
                        <Button color="primary" className="p-2 btn-block">
                          More <Badge color="light">{comments.length}</Badge>
                        </Button>
                      </Row>
                    </CardBody>
                  </Card>
                </Link>
              </div>
            );
          })
        : ''}
    </>
  );
};
export default PostCardOne;
