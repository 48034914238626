import axios from 'axios';
import {
  COMMENT_LOADING_FAILURE,
  COMMENT_LOADING_REQUEST,
  COMMENT_LOADING_SUCCESS,
  COMMENT_WRITE_FAILURE,
  COMMENT_WRITE_REQUEST,
  COMMENT_WRITE_SUCCESS,
} from '../types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

const fetchCommentsLoad = (postId) => {
  return axios.get(`/api/post/${postId}/comment`);
};

function* commentsLoad(action) {
  try {
    const result = yield call(fetchCommentsLoad, action.payload);
    yield put({ type: COMMENT_LOADING_SUCCESS, payload: result.data });
  } catch (e) {
    console.error(e);
    yield put({ type: COMMENT_LOADING_FAILURE, payload: e });
  }
}

function* watchCommentsLoad() {
  yield takeEvery(COMMENT_LOADING_REQUEST, commentsLoad);
}

// commentWrite
const fetchCommentWrite = ({ contents, postId, token, userId, userName }) => {
  //test
  // console.log('COMMENT WRITE SAGA PAYLOAD: ', payload);
  const config = {
    headers: {
      Authorization: token ?? '',
    },
  };
  return axios.post(
    `/api/post/${postId}/comment`,
    {
      contents,
      userId,
      userName,
    },
    config
  );
};

function* commentWrite(action) {
  try {
    const result = yield call(fetchCommentWrite, action.payload);
    //test
    console.log('COMMENT WRITE RES: ', result);
    yield put({ type: COMMENT_WRITE_SUCCESS, payload: result.data });
  } catch (e) {
    console.error(e);
    yield put({ type: COMMENT_WRITE_FAILURE, payload: e });
  }
}

function* watchCommentWrite() {
  yield takeEvery(COMMENT_WRITE_REQUEST, commentWrite);
}

function* commentSaga() {
  yield all([fork(watchCommentsLoad), fork(watchCommentWrite)]);
}

export default commentSaga;
