import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { POST_LOADING_FAILURE, POST_LOADING_REQUEST } from '../../redux/types';
import { Helmet } from 'react-helmet';
import { Alert, Row } from 'reactstrap';
import { GrowingSpinner } from '../../components/spinner/Spinner.js';
import PostCardOne from '../../components/post/PostCardOne.js';
import Category from '../../components/post/Category.js';

const PostCardList = () => {
  const { posts, categoryFindResult, loading, postCount } = useSelector(
    (state) => state.post
  );
  const dispatch = useDispatch();
  useEffect(() => {
    // 1번만 실행 됨
    dispatch({ type: POST_LOADING_REQUEST, payload: 0 }); // 임시
    //test
    console.log('이거는 최초에만 이잖아..?');
    // 취소 동작
    return () => {
      dispatch({ type: POST_LOADING_FAILURE });
    };
  }, [dispatch]);

  ////무한 스크롤//////  -> 다른 페이지 갔다 오면 초기화 되는 방식.
  // const skipNumberRef = useRef(0);
  const skipNumberRef = useRef(6); // 첫 로딩 시 6개 로딩
  // const postCountRef = useRef(0);
  // const postCountRef = useRef(postCount);
  const endMsg = useRef(false);

  // postCountRef.current = postCount - 6; // 첫 렌더링 후 렌더링 가능한 남은 포스트들
  const useOnScreen = (options) => {
    const lastPostElementRef = useRef();
    const [visible, setVisible] = useState(false);

    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        setVisible(entry.isIntersecting);

        if (entry.isIntersecting) {
          // let remainPostCount = postCountRef.current - skipNumberRef.current;
          // postCount(전체 개수)에서 바로 계산
          let remainPostCount = postCount - skipNumberRef.current;
          //test
          console.log(
            'POST,remain,skip: ',
            // postCountRef.current,
            postCount,
            remainPostCount,
            skipNumberRef.current
          );
          if (remainPostCount >= 0) {
            //test
            console.log('포스트 롤딩 디스패치');
            dispatch({
              type: POST_LOADING_REQUEST,
              payload: skipNumberRef.current + 6,
            });
            skipNumberRef.current += 6;
          } else {
            endMsg.current = true;
            console.log(endMsg.current);
          }
        }
      }, options);
      if (lastPostElementRef.current) {
        observer.observe(lastPostElementRef.current);
      }

      const LastElementReturnFunc = () => {
        if (lastPostElementRef.current) {
          observer.unobserve(lastPostElementRef.current);
        }
      };
      return LastElementReturnFunc;
    }, [lastPostElementRef, options]);
    return [lastPostElementRef, visible];
  };

  /////////////////////

  // const [lastPostElementRef, visible] = useOnScreen({ threshold: '1.0' });
  const [lastPostElementRef, visible] = useOnScreen({}); // 굳이 threshold가 없어도 되는게 맞긴 한데.

  // test
  console.log('post: ', posts);
  return (
    <>
      <Helmet title="Home" />
      <Row className="border-bottom border-top border-primary py-2 mb-3">
        <Category categories={categoryFindResult} />
      </Row>

      <Row>{posts ? <PostCardOne posts={posts} /> : <GrowingSpinner />}</Row>
      {loading ? (
        <GrowingSpinner />
      ) : (
        <div
          className="와우"
          style={{ background: 'black', width: '100%', height: '3px' }}
          ref={lastPostElementRef}
        ></div>
      )}

      {loading ? (
        ''
      ) : endMsg.current ? (
        <div>
          <Alert color="danger" className="text-center font-weight-border">
            더 이상의 포스트는 없습니다
          </Alert>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default PostCardList;
