import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CLEAR_ERROR_REQUEST, LOGIN_REQUEST } from '../../redux/types';
import {
  Alert,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  NavLink,
} from 'reactstrap';

const CommonComponent = ({
  inputAndLabelInfo,
  formDefaultState,
  linkName,
  dispatchType,
}) => {
  const [modal, setModal] = useState(false);
  const [localMsg, setLocalMsg] = useState('');
  const [form, setForm] = useState(formDefaultState);
  //   const [form, setForm] = useState({
  //     email: '',
  //     password: '',
  //   });
  const dispatch = useDispatch();
  const { errorMsg } = useSelector((state) => state.auth);
  useEffect(() => {
    try {
      setLocalMsg(errorMsg);
    } catch (e) {
      console.error(e);
    }
  }, [errorMsg]);

  const handleToggle = () => {
    modal && dispatch({ type: CLEAR_ERROR_REQUEST });
    // CLEAR_ERROR_SUCCESS랑 FAILURE는 언제 쓰는데?
    setModal(!modal);
  };

  const onFormChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    console.log('FORM: ', form);
    e.preventDefault();
    // const (Object.keys(formDefaultState)) = form;
    dispatch({
      type: dispatchType,
      payload: Object.keys(formDefaultState).reduce(
        (acc, cur) => ({
          ...acc,
          [cur]: form[cur],
        }),
        {}
      ),
      //   payload: {
      //     email,
      //     password,
      //   },
    });
  };

  return (
    <>
      <NavLink onClick={handleToggle} href="#" /** #는 왜죠 */>
        {linkName}
      </NavLink>

      <Modal isOpen={modal} /**toggle={handleToggle} 이거 왜 필요한거임 */>
        <ModalHeader>Login</ModalHeader>
        <ModalBody>
          {localMsg ? <Alert color="danger">{localMsg}</Alert> : null}
          <Form onSubmit={onSubmit}>
            <FormGroup>
              {inputAndLabelInfo.map((info) => {
                return (
                  <div key={info.id}>
                    <Label for={`${info.id}`}>{info.label}</Label>
                    <Input
                      type={info.type}
                      name={info.id}
                      id={info.id}
                      placeholder={info.placeholder}
                      onChange={onFormChange}
                    />
                  </div>
                );
              })}
              {/* <Label for="email">Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                placeholder="이메일"
                onChange={onFormChange}
              />
              <Label for="password">Password</Label>
              <Input
                type="password"
                name="password"
                id="password"
                placeholder="비번"
                onChange={onFormChange}
              /> */}
              <Button color="dark" style={{ marginTop: '2rem' }} block>
                {linkName}
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CommonComponent;
