import { takeEvery, put, fork, all, call } from 'redux-saga/effects';
import {
  CLEAR_ERROR_FAILURE,
  CLEAR_ERROR_REQUEST,
  CLEAR_ERROR_SUCCESS,
  LOADING_FAILURE,
  LOADING_REQUEST,
  LOADING_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  PROFILE_UPDATE_FAILURE,
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_SUCCESS,
  REGISTER_FAILURE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
} from '../types.js';
import axios from 'axios';
import { verify } from 'jsonwebtoken';

// 로그인

function fetchLogin(payload) {
  return axios.post('/api/auth', payload, {
    'Content-Type': 'Application/json',
  });
}

function* login(action) {
  try {
    const result = yield call(fetchLogin, action.payload);
    //
    console.log('fetch result: ', result);
    yield put({ type: LOGIN_SUCCESS, payload: result.data });
  } catch (e) {
    console.error('E: ', e);
    yield put({ type: LOGIN_FAILURE, payload: e.response });
  }
}

function* watchLogin() {
  yield takeEvery(LOGIN_REQUEST, login);
}

// 로그아웃
function* logout(action) {
  try {
    yield put({ type: LOGOUT_SUCCESS });
  } catch (e) {
    console.error('E: ', e);
    yield put({
      type: LOGOUT_FAILURE,
      payload: { data: { msg: '로그아웃 할 수 없습니다' } },
    });
  }
}

function* watchLogout() {
  yield takeEvery(LOGOUT_REQUEST, logout);
}

// loading
function fetchLoading(token) {
  //test
  console.log('fetchLoading - token: ', token);
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (token) {
    config.headers['Authorization'] = token;
  }
  return axios.get('/api/auth/user', config);
}

function* loading(action) {
  // test
  console.log('ACTION: ', action);
  try {
    const result = yield call(fetchLoading, action.payload);
    console.log('LOADING RESULT: ', result);
    yield put({ type: LOADING_SUCCESS, payload: result.data });
  } catch (e) {
    console.error('E: ', e);
    yield put({ type: LOADING_FAILURE, payload: e.response });
  }
}

function* watchLoading() {
  yield takeEvery(LOADING_REQUEST, loading);
}

// Register
function fetchRegister(data) {
  // test
  console.log('registering data: ', data);
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return axios.post('/api/user', data, config);
}

function* registering(action) {
  try {
    const result = yield call(fetchRegister, action.payload);
    // test
    console.log('REGI RESULT: ', result);
    yield put({ type: REGISTER_SUCCESS, payload: result.data });
  } catch (e) {
    console.error('E: ', e);
    yield put({ type: REGISTER_FAILURE, payload: e.response });
  }
}

function* watchRegistering() {
  yield takeEvery(REGISTER_REQUEST, registering);
}

// clear Error
function* clearingError(action) {
  try {
    yield put({ type: CLEAR_ERROR_SUCCESS });
  } catch (e) {
    console.error('E: ', e);
    yield put({ type: CLEAR_ERROR_FAILURE });
  }
}

function* watchClearingError() {
  yield takeEvery(CLEAR_ERROR_REQUEST, clearingError);
}

// Profile Update
const fetchProfileUpdate = (payload) => {
  const config = { headers: {} };
  if (payload.token) {
    config.headers['Authorization'] = payload.token;
  }
  return axios.put(`/api/auth/${payload.userName}/profile`, payload, config);
};
function* profileUpdate(action) {
  try {
    const result = yield call(fetchProfileUpdate, action.payload);
    yield put({ type: PROFILE_UPDATE_SUCCESS, payload: result.data });
  } catch (e) {
    console.error('E: ', e);
    yield put({ type: PROFILE_UPDATE_FAILURE, payload: e.response.data });
  }
}

function* watchProfileUpdate() {
  yield takeEvery(PROFILE_UPDATE_REQUEST, profileUpdate);
}

export default function* authSaga() {
  yield all([
    fork(watchLogin),
    fork(watchLogout),
    fork(watchLoading),
    fork(watchRegistering),
    fork(watchClearingError),
    fork(watchProfileUpdate),
  ]);
}

/**
 * 감시할 제너레이터,
 * 위로 실행될 제너레이터
 * 위에서 실행될 함수
 *
 * 요렇게 한 묶음을 기본 사가로 생각하면 된다고 함.
 *
 * 이 사가를 외부에서 사용하도록 generator 내부에서 all과 fork를 통해 보내면 됨.
 */
