import { useSelector } from 'react-redux';
import { Navigate, Route, useParams } from 'react-router-dom';
import Profile from '../publicRoute/Profile';

const ProfileProtectedRoute = () => {
  const { userName } = useParams();
  const authState = useSelector((state) => state.auth);
  return (
    <>
      {authState.userName && userName === authState.userName ? (
        <Profile />
      ) : (
        // <div />
        <>
          <Navigate to={{ pathname: '/' }} />
        </>
      )}
    </>
  );
};

export default ProfileProtectedRoute;
