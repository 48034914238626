import {
  Form,
  Collapse,
  Container,
  Nav,
  NavItem,
  Navbar,
  NavbarToggler,
  Button,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import LoginModal from './auth/LoginModal';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { LOGOUT_REQUEST } from '../redux/types';
import RegisterModal from './auth/RegisterModal';
import SearchInput from './search/SearchInput';

const AppNavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { isAuthenticated, userRole, user } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch(); // 로그아웃해야지

  const handleLogout = useCallback(() => {
    // state, selector업뎃마다 다시 만들기 싫어서.
    dispatch({
      type: LOGOUT_REQUEST,
    });
  }, [dispatch]);

  useEffect(() => {
    setIsOpen(false); // user가 바뀌면 토글 닫기.
  }, [user]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const addPostClick = () => {};

  const AuthLink = () => (
    <>
      <NavItem>
        {userRole === 'Master' ? (
          <Form className="col mt-2">
            <Link
              onClick={addPostClick}
              className="btn btn-success block test-white px-3"
              to={'post'}
            >
              add Post
            </Link>
          </Form>
        ) : (
          ''
        )}
      </NavItem>
      <NavItem className="d-flex justify-content-center">
        <Form className="col mt-2">
          {user && user.name ? (
            <Link to={`/user/${user.name}/profile`}>
              <Button outline color="light" className="px-3" block>
                <strong>{user ? `Welcom ${user.name}` : ''}</strong>
              </Button>
            </Link>
          ) : (
            <>
              <Button outline color="light" className="px-3" block>
                <strong>No User</strong>
              </Button>
            </>
          )}
        </Form>
      </NavItem>
      <NavItem className="d-flex justify-content-center">
        <Form className="col mt-2">
          {user ? (
            <Link onClick={handleLogout}>
              <Button outline color="light" className="px-3" block>
                <strong>{user ? `Logout` : ''}</strong>
              </Button>
            </Link>
          ) : (
            '유저가 아닌데용'
          )}
        </Form>
      </NavItem>
    </>
  );

  const GuestLink = () => (
    <>
      <NavItem>
        <RegisterModal />
      </NavItem>
      <NavItem>
        <LoginModal />
      </NavItem>
    </>
  );

  return (
    <>
      <Navbar color="dark" dark expand="lg" className="sticky-top">
        <Container style={{ display: 'flex', flexDirection: 'row' }}>
          <Link to={'/'} className="text-white text-decoration-none">
            홈페이지로 돌아가는 링크
          </Link>
          <NavbarToggler onClick={handleToggle} />
          <Collapse isOpen={isOpen} navbar>
            <SearchInput isOpen={isOpen} />
            <Nav className="ml-auto d-flex justify-content-around" navbar>
              {isAuthenticated /* 로그인 여부 */ ? (
                <AuthLink />
              ) : (
                // <></>
                // <></>
                <GuestLink />
              )}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
};
export default AppNavBar;
