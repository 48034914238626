import { Input, Label } from 'reactstrap';
import CommonComponent from './CommonComponent';
import { REGISTER_REQUEST } from '../../redux/types';

const RegisterModal = () => {
  return (
    <>
      <CommonComponent
        inputAndLabelInfo={[
          {
            label: '이름',
            id: 'name',
            type: 'text',
            placeholder: '이름 입력',
          },
          {
            label: '이메일',
            id: 'email',
            type: 'email',
            placeholder: '이메일 입력',
          },
          {
            label: '비밀번호',
            id: 'password',
            type: 'password',
            placeholder: '비번 입력',
          },
        ]}
        formDefaultState={{ name: '', email: '', password: '' }}
        linkName={'Register'}
        dispatchType={REGISTER_REQUEST}
      />
    </>
  );
};

export default RegisterModal;
