import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Progress,
} from 'reactstrap';
import CkEditor from '../../components/CkEditor';
import Myinit from '../../components/editor/UploadAdapter';
import { POST_WRITE_REQUEST } from '../../redux/types';
import { ClassicEditor } from 'ckeditor5';

const PostWrite = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const [formValues, setFormValues] = useState({
    title: '',
    contents: '',
    fileUrl: '',
    category: '',
  });
  const dispatch = useDispatch();

  const onChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    // test
    console.log('SUBMIT할 때:  ', formValues, e);
    const { title, contents, fileUrl, category } = formValues;
    const token = localStorage.getItem('token');
    dispatch({
      type: POST_WRITE_REQUEST,
      payload: { title, contents, fileUrl, category, token },
    });
  };

  const getDataFromEditor = (event, editor) => {
    const data = editor.getData();
    // test
    console.log('DATA IN POSTWRITE: ', data);

    // 처음 업로드된 이미지를 썸네일로.
    if (data && data.match('src=')) {
      const whereImg_start = data.indexOf('src=');
      let whereImg_end = '';
      let ext_name_find = '';
      let result_Image_Url = '';
      const ext_name = ['jpeg', 'jpg', 'png', 'gif'];

      for (let i = 0; i < ext_name.length; i++) {
        if (data.match(ext_name[i])) {
          ext_name_find = ext_name[i];
          whereImg_end = data.indexOf(`${ext_name[i]}`);
        }
      }

      if (ext_name_find == 'jpeg') {
        result_Image_Url = data.substring(
          // whereImg_start + 10,
          whereImg_start + 5,
          whereImg_end + 4
        );
        // test
        console.log('IMG_URL IN POSTWRITE', result_Image_Url);
      } else {
        result_Image_Url = data.substring(
          // whereImg_start + 10,
          whereImg_start + 5,
          whereImg_end + 3
        );
        // test
        console.log('IMG_URL IN POSTWRITE', result_Image_Url);
      }

      setFormValues({
        ...formValues,
        fileUrl: result_Image_Url,
        contents: data,
      });
    } else {
      setFormValues({
        ...formValues,
        fileUrl: process.env.REACT_APP_BASIC_THUMBNAIL_URL,
        contents: data,
      });
    }
  };
  return (
    <div>
      {isAuthenticated ? (
        <Form onSubmit={onSubmit}>
          <FormGroup className="mb-3">
            <Label for="title">Title</Label>
            <Input
              type="text"
              name="title"
              id="title"
              className="form-control"
              onChange={onChange}
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <Label for="category">Category</Label>
            <Input
              type="text"
              name="category"
              id="category"
              className="form-control"
              onChange={onChange}
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <Label for="content">Content</Label>
            <CkEditor
              onInit={Myinit}
              onBlur={getDataFromEditor}
              editor={ClassicEditor}
            />
            <Button
              color="success"
              block
              className=" col-md-2 offset-md-10 m-auto mt-3 "
            >
              제출하기
            </Button>
          </FormGroup>
        </Form>
      ) : (
        <Col width={50} className="p-5 m-5">
          <Progress animated color="info" />
        </Col>
      )}
    </div>
  );
};

export default PostWrite;
