import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  COMMENT_LOADING_REQUEST,
  COMMENT_WRITE_REQUEST,
} from '../../redux/types';
import { Button, Form, FormGroup, Input, Row, Container } from 'reactstrap';
import moment from 'moment';

export default function Comments({ id, userId, userName }) {
  const dispatch = useDispatch();
  const { comments } = useSelector((state) => state.comment);
  const inputRef = useRef(null);
  const [formValue, setFormValue] = useState({ contents: '' });
  //test
  console.log('코멘트 렌더링,id: ', id);
  console.log('COMMENTS: ', comments);
  const onSubmit = (e) => {
    e.preventDefault();
    const { contents } = formValue;
    const token = localStorage.getItem('token');
    const body = {
      contents,
      token,
      postId: id,
      userId,
      userName,
    };
    //test
    console.log('SUBMIT COMMENTS BODY: ', body);
    dispatch({ type: COMMENT_WRITE_REQUEST, payload: body });
    inputRef.current.value = '';
    setFormValue({ contents: '' });
  };

  const onChange = (e) => {
    setFormValue({
      ...formValue,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    dispatch({ type: COMMENT_LOADING_REQUEST, payload: id });
    console.log('왜 자꾸 렌더링');
  }, [dispatch, id]);

  return (
    <>
      <Container className="mb-3 border border-blue rounded">
        {Array.isArray(comments)
          ? comments.map(({ contents, creator, creatorName, date, _id }) => (
              <div key={_id} style={{ width: '100%' }}>
                <Row
                  className="justify-content-between p-2 d-flex"
                  style={{
                    width: '100% !important',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '1rem',
                    flexWrap: 'nowrap',
                  }}
                >
                  <div
                    className="font-weight-bold"
                    style={{ width: 'auto', fontSize: 'small' }}
                  >
                    {creatorName ? creatorName : creator}
                  </div>
                  <div
                    className="text-small"
                    style={{
                      fontSize: 'small',
                      display: 'flex',
                      gap: '0.5rem',
                      width: 'auto',
                    }}
                  >
                    <span
                      className="font-weight-bold"
                      style={{ fontWeight: 'bold' }}
                    >
                      {/* {date.split(' ')[0]} */}
                      {moment(date).format('YYYY-MM-DD')}
                    </span>
                    <span
                      className="font-weight-light"
                      style={{ fontWeight: 'lighter' }}
                    >
                      {moment(date).format('hh:mm:ss')}
                    </span>
                  </div>
                </Row>
                <Row className="p-2">
                  <div>{contents}</div>
                </Row>
                <hr />
              </div>
            ))
          : 'creator'}
      </Container>
      <Form onSubmit={onSubmit}>
        <FormGroup>
          <Row className="p-2">
            <div className="font-weight-bold m-1">Make Comment</div>
            <div className="my-1">
              <Input
                innerRef={inputRef}
                type="textarea"
                name="contents"
                id="contents"
                onChange={onChange}
                placeholder="Comment"
              />
              <Button
                color="primary"
                block
                className="mt-2 offset-md-10 col-md-2"
              >
                Submit
              </Button>
            </div>
          </Row>
        </FormGroup>
      </Form>
    </>
  );
}
