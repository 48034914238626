import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CLEAR_ERROR_REQUEST, PROFILE_UPDATE_REQUEST } from '../../redux/types';
import { Helmet } from 'react-helmet';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';

const Profile = () => {
  const { userId, userName, errorMsg, successMsg } = useSelector(
    (state) => state.auth
  );
  //test
  console.log('ERRORMSG,successMSG: ', errorMsg, successMsg);
  const [formValues, setFormValues] = useState({
    originalPassword: '',
    newPassword: '',
    reSubmittedPassword: '',
  });
  const dispatch = useDispatch();

  const onChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const { originalPassword, newPassword, reSubmittedPassword } = formValues;
    const token = localStorage.getItem('token');

    const body = {
      prevPassword: originalPassword,
      password: newPassword,
      reSubmittedPassword,
      userId,
      userName,
      token,
    };

    // 일단 에러 메시지 초기화하고
    dispatch({
      type: CLEAR_ERROR_REQUEST,
    });

    // 프로파일 업뎃
    dispatch({
      type: PROFILE_UPDATE_REQUEST,
      payload: body,
    });
  };
  return (
    <>
      <Helmet title={`Profile | ${userName}님의 프로필`} />
      <Col sm={'12'} md={{ size: 6, offset: 3 }}>
        <Card>
          <CardHeader>
            <strong>Edit Password</strong>
          </CardHeader>
          <CardBody>
            <Form onSubmit={onSubmit}>
              <FormGroup>
                <Label for="title">기존 비밀 번호</Label>
                <Input
                  type="password"
                  name="originalPassword"
                  id="originalPassword"
                  className="form-control mb-2"
                  onChange={onChange}
                />
                {/* {errorMsg ? <Alert color="danger">{errorMsg}</Alert> : null} */}
              </FormGroup>
              <FormGroup>
                <Label for="title">새로운 비밀 번호</Label>
                <Input
                  type="password"
                  name="newPassword"
                  id="newPassword"
                  className="form-control mb-2"
                  onChange={onChange}
                />
                {/* {errorMsg ? <Alert color="danger">{errorMsg}</Alert> : null} */}
              </FormGroup>
              <FormGroup>
                <Label for="title">비밀 번호 확인</Label>
                <Input
                  type="password"
                  name="reSubmittedPassword"
                  id="reSubmittedPassword"
                  className="form-control mb-2"
                  onChange={onChange}
                />
                {errorMsg ? <Alert color="danger">{errorMsg}</Alert> : null}
              </FormGroup>
              <Button
                color="success"
                block
                className="mt-4 mb-4 col-md-3 offset-9"
              >
                제출하기
              </Button>
              {successMsg ? <Alert color="success">{successMsg}</Alert> : null}
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Profile;
