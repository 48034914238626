import { Row, Col } from 'reactstrap';

const Footer = () => {
  const thisYear = new Date().getFullYear();
  return (
    <div>
      <Row id="main-footer" className="text-center p-2">
        <Col>
          <p>
            Copyright&copy;
            <span>{`${thisYear}`}</span>
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default Footer;
