import Header from '../components/header.js';
import Footer from '../components/footer.js';
import AppNavBar from '../components/AppNavBar.js';
import { Container } from 'reactstrap';
import { Route, Switch, Navigate, Routes } from 'react-router-dom';
import PostCardList from './publicRoute/PostCardList.js';
import PostWrite from './publicRoute/PostWrite.js';
import PostDetail from './publicRoute/PostDetail.js';
import CategoryResult from './publicRoute/CategoryResult.js';
import Search from './publicRoute/Search.js';
import EditProtectedRoute from './protectedRoute/EditProtectedRoute.js';
import PostEdit from './publicRoute/PostEdit.js';
import { Fragment } from 'react';
import ProfileProtectedRoute from './protectedRoute/ProfileProtectedRoute.js';

const MyRouter = () => (
  <>
    <AppNavBar />
    <Header />
    <Container>
      <Routes>
        <Route path="/" element={<PostCardList />}></Route>
        <Route path="/post" element={<PostWrite />}></Route>
        <Route path="/post/:id" element={<PostDetail />}></Route>
        <Route path="/post/:id/edit" element={<EditProtectedRoute />}></Route>
        <Route
          path="/user/:userName/profile"
          element={<ProfileProtectedRoute />}
        ></Route>
        <Route
          path="/post/category/:categoryName"
          element={<CategoryResult />}
        ></Route>
        <Route path="/search/:searchTerm" element={<Search />}></Route>
        <Route path="*" element={<Navigate replace to={'/'} />}>
          {/** 리다이렉트 : 다른 경로는 전부 홈으로 보내버리기 */}
        </Route>
      </Routes>
    </Container>
    <Footer />
  </>
);

export default MyRouter;
