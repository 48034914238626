import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Mention,
  Paragraph,
  Undo,
  Code,
  Heading,
  Indent,
  IndentBlock,
  Underline,
  Strikethrough,
  BlockQuote,
  Font,
  ImageToolbar,
  ImageUpload,
  ImageResize,
  SimpleUploadAdapter,
  Table,
  TableToolbar,
  TextTransformation,
  Link,
  MediaEmbed,
  PasteFromOffice,
  Image,
  ImageStyle,
  List,
  Alignment,
  AutoImage,
  EasyImage,
  ImageInsert,
  FileRepository,
} from 'ckeditor5';
import { SlashCommand } from 'ckeditor5-premium-features';

import 'ckeditor5/ckeditor5.css';
// import dotenv from 'dotenv';
// dotenv.config();
// import 'ckeditor5-premium-features/ckeditor5-premium-features.css';

export default function CkEditor({
  onInit,
  onBlur,
  editor,
  disabled = false,
  data = null,
}) {
  console.log('DATA in ck: ', data, typeof data);
  return (
    <CKEditor
      onBlur={onBlur}
      onInit={onInit}
      // editor={ClassicEditor}
      editor={editor}
      disabled={disabled}
      data={`${data}`}
      config={{
        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'code',
            '|',
            'fontSize',
            'fontColor',
            'fontBackgroundColor',
            '|',
            'alignment',
            'outdent',
            'indent',
            'bulletedList',
            'numberedList',
            'blockQuote',
            '|',
            'link',
            'insertTable',

            'ImageUpload',
            'insertFileAndImage',
            'mediaEmbed',
            '|',
            'undo',
            'redo',
          ],
        },
        plugins: [
          Bold,
          Essentials,
          Italic,
          Mention,
          Paragraph,
          Undo,
          Code,
          Heading,
          Indent,
          IndentBlock,
          Underline,
          Strikethrough,
          BlockQuote,
          Font,
          ImageToolbar,
          ImageUpload,
          Image,
          ImageResize,
          SimpleUploadAdapter,
          Table,
          TableToolbar,
          TextTransformation,
          Link,
          MediaEmbed,
          PasteFromOffice,
          ImageStyle,
          List,
          Alignment,
          AutoImage,
        ],
        // licenseKey: '<YOUR_LICENSE_KEY>',
        mention: {
          // Mention configuration
        },
        heading: {
          options: [
            {
              model: 'paragraph',
              view: 'p',
              title: '본문',
              class: 'ck-heading_paragraph',
            },
            {
              model: 'heading1',
              view: 'h2',
              title: '헤더1',
              class: 'ck-heading_heading1',
            },
            {
              model: 'heading2',
              view: 'h2',
              title: '헤더2',
              class: 'ck-heading_heading2',
            },
          ],
        },
        initialData: '<p>Hello from CKEditor 5 in React!</p>',
        simpleUpload: {
          uploadUrl: `${process.env.REACT_APP_BASE_URL}/api/post/image`,
          headers: {
            X_CSRF_TOKEN: 'CSRF_TOKEN',
          },
        },
      }}
    />
  );
}
