import { Col, Row } from 'reactstrap';

const Header = () => (
  <div id="page-header" className="mb-3">
    <Row>
      <Col md="6" sm="auto" className="text-center m-auto mt-5">
        <h1>Blog</h1>
        <p>MERN 스택 제작 페이지</p>
      </Col>
    </Row>
  </div>
);

export default Header;
