/**
 *  Redux Types
 */

// LOGIN
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

// LOGOUT
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

// LOADING
export const LOADING_REQUEST = 'LOADING_REQUEST';
export const LOADING_SUCCESS = 'LOADING_SUCCESS';
export const LOADING_FAILURE = 'LOADING_FAILURE';

// Register
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

// CLEAR ERROR
//  모달 창에서 로그인할건데, 모달 창을 끌 때, error메시지 등을 처리하기 위해.
export const CLEAR_ERROR_REQUEST = 'CLEAR_ERROR_REQUEST';
export const CLEAR_ERROR_SUCCESS = 'CLEAR_ERROR_SUCCESS';
export const CLEAR_ERROR_FAILURE = 'CLEAR_ERROR_FAILURE';

// POST LOADING
export const POST_LOADING_REQUEST = 'POST_LOADING_REQUEST';
export const POST_LOADING_SUCCESS = 'POST_LOADING_SUCCESS';
export const POST_LOADING_FAILURE = 'POST_LOADING_FAILURE';

// POST WRITING
export const POST_WRITE_REQUEST = 'POST_WRITE_REQUEST';
export const POST_WRITE_SUCCESS = 'POST_WRITE_SUCCESS';
export const POST_WRITE_FAILURE = 'POST_WRITE_FAILURE';

// POST UPLOADING
export const POST_UPLOADING_REQUEST = 'POST_UPLOADING_REQUEST';
export const POST_UPLOADING_SUCCESS = 'POST_UPLOADING_SUCCESS';
export const POST_UPLOADING_FAILURE = 'POST_UPLOADING_FAILURE';

// POST DETAIL LOADING
export const POST_DETAIL_LOADING_REQUEST = 'POST_DETAIL_LOADING_REQUEST';
export const POST_DETAIL_LOADING_SUCCESS = 'POST_DETAIL_LOADING_SUCCESS';
export const POST_DETAIL_LOADING_FAILURE = 'POST_DETAIL_LOADING_FAILURE';

// POST EDIT LOADING
export const POST_EDIT_LOADING_REQUEST = 'POST_EDIT_LOADING_REQUEST';
export const POST_EDIT_LOADING_SUCCESS = 'POST_EDIT_LOADING_SUCCESS';
export const POST_EDIT_LOADING_FAILURE = 'POST_EDIT_LOADING_FAILURE';

// POST EDIT UPLOADING
export const POST_EDIT_UPLOADING_REQUEST = 'POST_EDIT_UPLOADING_REQUEST';
export const POST_EDIT_UPLOADING_SUCCESS = 'POST_EDIT_UPLOADING_SUCCESS';
export const POST_EDIT_UPLOADING_FAILURE = 'POST_EDIT_UPLOADING_FAILURE';

// POST DELETE
export const POST_DELETE_REQUEST = 'POST_DELETE_REQUEST';
export const POST_DELETE_SUCCESS = 'POST_DELETE_SUCCESS';
export const POST_DELETE_FAILURE = 'POST_DELETE_FAILURE';

// COMMENT LOADING
export const COMMENT_LOADING_REQUEST = 'COMMENT_LOADING_REQUEST';
export const COMMENT_LOADING_SUCCESS = 'COMMENT_LOADING_SUCCESS';
export const COMMENT_LOADING_FAILURE = 'COMMENT_LOADING_FAILURE';

// COMMENT WRITE
export const COMMENT_WRITE_REQUEST = 'COMMENT_WRITE_REQUEST';
export const COMMENT_WRITE_SUCCESS = 'COMMENT_WRITE_SUCCESS';
export const COMMENT_WRITE_FAILURE = 'COMMENT_WRITE_FAILURE';

// CATEGORY RESULT LOADING
export const CATEGORY_RESULT_LOADING_REQUEST =
  'CATEGORY_RESULT_LOADING_REQUEST';
export const CATEGORY_RESULT_LOADING_SUCCESS =
  'CATEGORY_RESULT_LOADING_SUCCESS';
export const CATEGORY_RESULT_LOADING_FAILURE =
  'CATEGORY_RESULT_LOADING_FAILURE';

// SEARCH
export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_FAILURE = 'SEARCH_FAILURE';

// PROFILE_UPDATE
export const PROFILE_UPDATE_REQUEST = 'PROFILE_UPDATE_REQUEST';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const PROFILE_UPDATE_FAILURE = 'PROFILE_UPDATE_FAILURE';
