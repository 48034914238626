import {
  COMMENT_LOADING_FAILURE,
  COMMENT_LOADING_REQUEST,
  COMMENT_LOADING_SUCCESS,
  COMMENT_WRITE_FAILURE,
  COMMENT_WRITE_REQUEST,
  COMMENT_WRITE_SUCCESS,
} from '../types';

const initialCommentState = {
  comments: [],
  creatorId: '', // 이거 뭐때매 있는거지? 댓글 위한 state에 필요 없을 것 같은데.. post의 id면 몰라도.
  isLoading: false,
  isAuthenticated: false,
};

const commentReducer = (state = initialCommentState, action) => {
  switch (action.type) {
    case COMMENT_LOADING_REQUEST:
      return { ...state, isLoading: true };
    case COMMENT_LOADING_SUCCESS:
      return {
        ...state,
        comments: action.payload, //포스트내 모든 댓글 가져와서 저장.
        isLoading: false,
      };
    case COMMENT_LOADING_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case COMMENT_WRITE_REQUEST:
      return { ...state, isLoading: true };
    case COMMENT_WRITE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        comments: [...state.comments, action.payload],
      };
    case COMMENT_WRITE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default commentReducer;
